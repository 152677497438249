// Add your JS customizations here
jQuery(document).ready(function($) {

	let wowNew = new WOW(
		{
			boxClass:     'wow',      // default
			animateClass: 'animated', // default
			offset:       0,          // default
			mobile:       false,       // default
			live:         true        // default
		}
	)
	wowNew.init();

	$('#wrapper-footer .footer-top-left li').addClass('font-navbar');

	$('#wrapper-footer .footer-bottom li').addClass('font-navbar');

	$('.navbar-active .navbar-active-wrapper li').addClass('font-navbar');

	$('.navbar-active .about-dropdown li').addClass('font-3');

	$('#main-nav .navbar-container li').addClass('font-navbar');

	$('.navbar-mobile-active-wrapper .mobile-menu li').addClass('font-navbar');

	$('.navbar-mobile-active-wrapper .about-menu-wrapper li').addClass('font-3');

	$('.about-dropdown #about-dropdown-nav-2 li').addClass('font-3');

	function activateDropdown(name) {
		$('#wrapper-navbar .navbar-bottom #main-menu li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			$('#wrapper-navbar').toggleClass('menu-active');

			$('body').css('overflow-y', 'hidden');

			$('#wrapper-navbar .navbar-bottom #main-menu li.'+ name +'-nav-link').toggleClass('active');

			$('#main-nav').addClass('active');

			if($('#wrapper-navbar .'+ name +'-container').hasClass('active')) {
				$('#main-nav').removeClass('active');
				$('body').css('overflow-y', 'auto');
			} else {
				$('#main-nav').addClass('active');
			}

			$('#wrapper-navbar .'+ name +'-dropdown').toggleClass('active');

			if($('#wrapper-navbar .'+ name +'-container').hasClass('active')) {
				$('#wrapper-navbar .'+ name +'-container').addClass('inactive');
				$('#wrapper-navbar .'+ name +'-container').removeClass('active');
			} else {
				$('#wrapper-navbar .'+ name +'-container').addClass('active');

				if($('#wrapper-navbar .'+ name +'-container').hasClass('inactive')) {
					$('#wrapper-navbar .'+ name +'-container').removeClass('inactive');
					$('#wrapper-navbar .'+ name +'-container').addClass('active');
				}
			}

			if($('#wrapper-navbar .'+ name +'-dropdown-overlay').hasClass('active')) {
				$('#wrapper-navbar .'+ name +'-dropdown-overlay').addClass('inactive');
				$('#wrapper-navbar .'+ name +'-dropdown-overlay').removeClass('active');
			} else {
				$('#wrapper-navbar .'+ name +'-dropdown-overlay').addClass('active');

				if($('#wrapper-navbar .'+ name +'-dropdown-overlay').hasClass('inactive')) {
					$('#wrapper-navbar .'+ name +'-dropdown-overlay').removeClass('inactive');
					$('#wrapper-navbar .'+ name +'-dropdown-overlay').addClass('active');
				}
			}

		})

		$('#wrapper-navbar .'+ name +'-dropdown .close').on('click', () => {
			$('#wrapper-navbar .'+ name +'-dropdown-overlay').removeClass('active');
			$('#wrapper-navbar .'+ name +'-dropdown-overlay').addClass('inactive');

			$('#wrapper-navbar').removeClass('menu-active');

			$('body').css('overflow-y', 'auto');

			$('#wrapper-navbar .'+ name +'-container').addClass('inactive');
			$('#wrapper-navbar .'+ name +'-container').removeClass('active');

			$('#wrapper-navbar .navbar-bottom #main-menu li.'+ name +'-nav-link').toggleClass('active');

			$('#main-nav').removeClass('active');

			$('#wrapper-navbar .'+ name +'-dropdown').toggleClass('active');
		});
	}

	activateDropdown('about');
	activateDropdown('treatments');
	activateDropdown('conditions');
	activateDropdown('orthodontics');

	function activateDropdownFooter(name) {
		$('.site-footer #footer-top-nav li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			$('.navbar-active #main-menu-active li.'+ name +'-nav-link').toggleClass('active');

			$('.navbar-active .'+ name +'-dropdown').toggleClass('active');

			if($('.navbar-active .'+ name +'-container').hasClass('active')) {
				$('.navbar-active .'+ name +'-container').addClass('inactive');
				$('.navbar-active .'+ name +'-container').removeClass('active');
			} else {
				$('.navbar-active .'+ name +'-container').addClass('active');

				if($('.navbar-active .'+ name +'-container').hasClass('inactive')) {
					$('.navbar-active .'+ name +'-container').removeClass('inactive');
					$('.navbar-active .'+ name +'-container').addClass('active');
				}
			}

			if($('.navbar-active .'+ name +'-dropdown-overlay').hasClass('active')) {
				$('.navbar-active .'+ name +'-dropdown-overlay').addClass('inactive');
				$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('active');
			} else {
				$('.navbar-active .'+ name +'-dropdown-overlay').addClass('active');

				if($('.navbar-active .'+ name +'-dropdown-overlay').hasClass('inactive')) {
					$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('inactive');
					$('.navbar-active .'+ name +'-dropdown-overlay').addClass('active');
				}
			}

		});
	}

	activateDropdownFooter('treatments');
	activateDropdownFooter('conditions');
	activateDropdownFooter('orthodontics');

	function disableOtherDropdowns(name) {
		$('#wrapper-navbar .navbar-bottom #main-menu li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			if(name == 'about') {
				$('#wrapper-navbar .treatments-container').removeClass('active');
				$('#wrapper-navbar .treatments-container').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.treatments-nav-link').removeClass('active');

				$('#wrapper-navbar .conditions-container').removeClass('active');
				$('#wrapper-navbar .conditions-container').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.conditions-nav-link').removeClass('active');

				$('#wrapper-navbar .orthodontics-container').removeClass('active');
				$('#wrapper-navbar .orthodontics-container').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'treatments') {
				$('#wrapper-navbar .about-container').removeClass('active');
				$('#wrapper-navbar .about-container').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.about-nav-link').removeClass('active');

				$('#wrapper-navbar .conditions-container').removeClass('active');
				$('#wrapper-navbar .conditions-container').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.conditions-nav-link').removeClass('active');

				$('#wrapper-navbar .orthodontics-container').removeClass('active');
				$('#wrapper-navbar .orthodontics-container').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'conditions') {
				$('#wrapper-navbar .about-container').removeClass('active');
				$('#wrapper-navbar .about-container').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.about-nav-link').removeClass('active');

				$('#wrapper-navbar .treatments-container').removeClass('active');
				$('#wrapper-navbar .treatments-container').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.treatments-nav-link').removeClass('active');

				$('#wrapper-navbar .orthodontics-container').removeClass('active');
				$('#wrapper-navbar .orthodontics-container').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .orthodontics-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .orthodontics-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'orthodontics') {
				$('#wrapper-navbar .about-container').removeClass('active');
				$('#wrapper-navbar .about-container').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .about-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .about-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.about-nav-link').removeClass('active');

				$('#wrapper-navbar .treatments-container').removeClass('active');
				$('#wrapper-navbar .treatments-container').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .treatments-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .treatments-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.treatments-nav-link').removeClass('active');

				$('#wrapper-navbar .conditions-container').removeClass('active');
				$('#wrapper-navbar .conditions-container').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('active');
				$('#wrapper-navbar .conditions-dropdown-overlay').removeClass('inactive');
				$('#wrapper-navbar .conditions-dropdown').removeClass('active');
				$('#wrapper-navbar .navbar-bottom #main-menu li.conditions-nav-link').removeClass('active');
			}


		});
	}

	disableOtherDropdowns('about');
	disableOtherDropdowns('conditions');
	disableOtherDropdowns('treatments');
	disableOtherDropdowns('orthodontics');

	function disableOtherDropdownsFooter(name) {
		$('.site-footer #footer-top-nav li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			if(name == 'about') {
				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'treatments') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'conditions') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'orthodontics') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');
			}


		});
	}

	disableOtherDropdownsFooter('about');
	disableOtherDropdownsFooter('treatments');
	disableOtherDropdownsFooter('conditions');
	disableOtherDropdownsFooter('orthodontics');

	function activateDropdownActive(name) {
		$('.navbar-active #main-menu-active li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			$('.navbar-active #main-menu-active li.'+ name +'-nav-link').toggleClass('active');

			$('.navbar-active .'+ name +'-dropdown').toggleClass('active');

			if($('.navbar-active .'+ name +'-container').hasClass('active')) {
				$('.navbar-active .'+ name +'-container').addClass('inactive');
				$('.navbar-active .'+ name +'-container').removeClass('active');
			} else {
				$('.navbar-active .'+ name +'-container').addClass('active');

				if($('.navbar-active .'+ name +'-container').hasClass('inactive')) {
					$('.navbar-active .'+ name +'-container').removeClass('inactive');
					$('.navbar-active .'+ name +'-container').addClass('active');
				}
			}

			if($('.navbar-active .'+ name +'-dropdown-overlay').hasClass('active')) {
				$('.navbar-active .'+ name +'-dropdown-overlay').addClass('inactive');
				$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('active');
			} else {
				$('.navbar-active .'+ name +'-dropdown-overlay').addClass('active');

				if($('.navbar-active .'+ name +'-dropdown-overlay').hasClass('inactive')) {
					$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('inactive');
					$('.navbar-active .'+ name +'-dropdown-overlay').addClass('active');
				}
			}

		})

		$('.navbar-active .'+ name +'-dropdown .close').on('click', () => {
			$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('active');
			$('.navbar-active .'+ name +'-dropdown-overlay').addClass('inactive');

			$('.navbar-active .'+ name +'-container').addClass('inactive');
			$('.navbar-active .'+ name +'-container').removeClass('active');

			setTimeout(() => {
				$('.navbar-active .'+ name +'-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .'+ name +'-container').removeClass('inactive');
			}, 400)

			$('.navbar-active #main-menu-active li.'+ name +'-nav-link').toggleClass('active');

			$('.navbar-active .'+ name +'-dropdown').toggleClass('active');
		});
	}

	activateDropdownActive('about');
	activateDropdownActive('treatments');
	activateDropdownActive('conditions');
	activateDropdownActive('orthodontics');

	function disableOtherDropdownsActive(name) {
		$('.navbar-active #main-menu-active li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			if(name == 'about') {
				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'treatments') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'conditions') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');

				$('.navbar-active .orthodontics-container').removeClass('active');
				$('.navbar-active .orthodontics-container').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('active');
				$('.navbar-active .orthodontics-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .orthodontics-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.orthodontics-nav-link').removeClass('active');
			}

			if(name == 'orthodontics') {
				$('.navbar-active .about-container').removeClass('active');
				$('.navbar-active .about-container').removeClass('inactive');
				$('.navbar-active .about-dropdown-overlay').removeClass('active');
				$('.navbar-active .about-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .about-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.about-nav-link').removeClass('active');

				$('.navbar-active .conditions-container').removeClass('active');
				$('.navbar-active .conditions-container').removeClass('inactive');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('active');
				$('.navbar-active .conditions-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .conditions-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.conditions-nav-link').removeClass('active');

				$('.navbar-active .treatments-container').removeClass('active');
				$('.navbar-active .treatments-container').removeClass('inactive');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('active');
				$('.navbar-active .treatments-dropdown-overlay').removeClass('inactive');
				$('.navbar-active .treatments-dropdown').removeClass('active');
				$('.navbar-active #main-menu-active li.treatments-nav-link').removeClass('active');
			}

		});
	}

	disableOtherDropdownsActive('about');
	disableOtherDropdownsActive('treatments');
	disableOtherDropdownsActive('conditions');
	disableOtherDropdownsActive('orthodontics');


	if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
		$('.navbar-active').addClass('active');
		$('.navbar-active').removeClass('inactive');
		disableOtherDropdowns('about');
		disableOtherDropdowns('conditions');
		disableOtherDropdowns('treatments');
		disableOtherDropdowns('orthodontics');
	} else {
		$('.navbar-active').removeClass('active');
		$('.navbar-active').addClass('inactive');
	}

	$(document).scroll(function() {

		if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
			$('.navbar-active').addClass('active');
			$('.navbar-active').removeClass('inactive');
		} else {
			$('.navbar-active').removeClass('active');
			$('.navbar-active').addClass('inactive');
		}

	})

	$('#wrapper-navbar .navbar-bottom-mobile .top').on('click', (e) => {
		e.preventDefault();
		$('#wrapper-navbar .navbar-bottom-mobile').addClass('active');

		$('.navbar-mobile-active').addClass('active');
		$('.navbar-mobile-active').removeClass('inactive');
	});

	$('.navbar-mobile .top').on('click', (e) => {
		e.preventDefault();

		$('.navbar-mobile').removeClass('active');

		$('.navbar-mobile-active').addClass('active');
		$('.navbar-mobile-active').removeClass('inactive');
	});

	$('.navbar-mobile-active .close').on('click', (e) => {
		e.preventDefault();

		$('#wrapper-navbar .navbar-bottom-mobile').removeClass('active');

		if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
			$('.navbar-mobile').addClass('active');
		}

		// $('.navbar-mobile-active .mobile-menu').toggleClass('active');
		$('.navbar-mobile-active .about-menu-wrapper').removeClass('active');
		$('.navbar-mobile-active .conditions-menu-wrapper').removeClass('active');
		$('.navbar-mobile-active .treatments-menu-wrapper').removeClass('active');
		$('.navbar-mobile-active .orthodontics-menu-wrapper').removeClass('active');


		$('.navbar-mobile-active').removeClass('active');
		$('.navbar-mobile-active').addClass('inactive');
	});

	if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
		if(!$('.navbar-mobile-active').hasClass('active')) {
			$('.navbar-mobile').addClass('active');
			$('.navbar-mobile').removeClass('inactive');
			$('#wrapper-navbar .navbar-bottom-mobile').addClass('active');
		}
	} else {
		$('.navbar-mobile').removeClass('active');
		$('.navbar-mobile').addClass('inactive');
		$('#wrapper-navbar .navbar-bottom-mobile').removeClass('active');
	}

	$(document).scroll(function() {

		if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
			if(!$('.navbar-mobile-active').hasClass('active')) {
				$('.navbar-mobile').addClass('active');
				$('.navbar-mobile').removeClass('inactive');
				$('#wrapper-navbar .navbar-bottom-mobile').addClass('active');
			}
		} else {
			$('.navbar-mobile').removeClass('active');
			$('.navbar-mobile').addClass('inactive');
			$('#wrapper-navbar .navbar-bottom-mobile').removeClass('active');
		}

	});

	function mobileMenuToggle(name) {
		$('.navbar-mobile-active .mobile-menu .'+name+'-nav-link').on('click', (e) => {
			e.preventDefault();
			$('.navbar-mobile-active .mobile-menu').toggleClass('active');
			$('.navbar-mobile-active .'+name+'-menu-wrapper').toggleClass('active');

			if(name == 'about') {
				$('.navbar-mobile-active .conditions-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .treatments-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .orthodontics-menu-wrapper').removeClass('active');
			}

			if(name == 'treatments') {
				$('.navbar-mobile-active .conditions-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .about-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .orthodontics-menu-wrapper').removeClass('active');
			}

			if(name == 'conditions') {
				$('.navbar-mobile-active .about-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .treatments-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .orthodontics-menu-wrapper').removeClass('active');
			}

			if(name == 'orthodontics') {
				$('.navbar-mobile-active .about-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .treatments-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .conditions-menu-wrapper').removeClass('active');
			}
		});
	}

	mobileMenuToggle('about');
	mobileMenuToggle('treatments');
	mobileMenuToggle('conditions');
	mobileMenuToggle('orthodontics');

	function mobileDropdownClose() {
		$('.navbar-mobile-active .menu-wrapper > .font-navbar').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('.navbar-mobile-active .mobile-menu').toggleClass('active');
				$('.navbar-mobile-active .'+name+'-menu-wrapper').toggleClass('active');

				$('.navbar-mobile-active .about-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .treatments-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .conditions-menu-wrapper').removeClass('active');
				$('.navbar-mobile-active .orthodontics-menu-wrapper').removeClass('active');
			});
		});
	}

	mobileDropdownClose()

	function footerMobileMenuToggle(name) {
		$('.site-footer #footer-top-nav-mobile li.'+ name +'-nav-link').on('click', (e) => {
			e.preventDefault();

			if($('.navbar-mobile-active').hasClass('active')) {
				$('.navbar-mobile-active').removeClass('active');
				$('.navbar-mobile-active').addClass('inactive');
			} else {
				$('.navbar-mobile-active').addClass('active');
				$('.navbar-mobile-active').removeClass('inactive');
			}

			$('.navbar-mobile-active .mobile-menu').removeClass('active');
			$('.navbar-mobile-active .'+name+'-menu-wrapper').toggleClass('active');
		});
	}

	footerMobileMenuToggle('treatments');
	footerMobileMenuToggle('conditions');
	footerMobileMenuToggle('orthodontics');

	if($('.home-entry-slider').length > 0) {
		$('.home-entry-slider').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [

				{
					breakpoint: 1850,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					}
				},
				{
					breakpoint: 1650,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					}
				},
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					}
				},
				{
					breakpoint: 650,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					}
				}
			]
		});
	}

	if($('.team-members-block .content').length > 0) {
		$('.team-members-block .content').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			autoplay: false,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						autoplay: false,
					}
				}
			]
		});
	}

	if($('.treatment-spotlight .treatment-spotlight-wrapper.mobile').length > 0) {
		$('.treatment-spotlight .treatment-spotlight-wrapper.mobile').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			dots: true,
			autoplay: false,
			variableWidth: true
		});
	}

	if($('.videos-block .videos').length > 0) {
		$('.videos-block .videos').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			centerMode: true,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: false,
						autoplay: false,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}

	if($('.related-conditions-block .content').length > 0) {
		$('.related-conditions-block .content').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			centerMode: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: false,
						autoplay: false,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}

	if($('.related-testimonials-block .content').length > 0) {
		$('.related-testimonials-block .content').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			centerMode: false,
		});
	}

	if($('.at-a-glance.mobile').length > 0) {
		$('.at-a-glance.mobile .items-wrapper').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			dots: true,
			autoplay: false,
			centerMode: false,
		});
	}

	if($('.before-after .images-wrapper').length > 0) {
		$('.before-after .images-wrapper').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 3000,
			centerMode: false,
		});
	}

	if($('.related-articles .content').length > 0) {
		$('.related-articles .content').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			centerMode: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: false,
						autoplay: false,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}

	let treatmentItemWidth = $('.treatment-spotlight .treatment-spotlight-wrapper.mobile').outerWidth();

	$('.treatment-spotlight .treatment-spotlight-wrapper.mobile .treatment-spotlight-item').css('with', treatmentItemWidth - 60);
	$('.treatment-spotlight .treatment-spotlight-wrapper.mobile .treatment-spotlight-item').css('max-width', treatmentItemWidth - 60);

	$(window).on('resize', function() {
		let treatmentItemWidth = $('.treatment-spotlight .treatment-spotlight-wrapper.mobile').outerWidth();

		$('.treatment-spotlight .treatment-spotlight-wrapper.mobile .treatment-spotlight-item').css('with', treatmentItemWidth - 60);
		$('.treatment-spotlight .treatment-spotlight-wrapper.mobile .treatment-spotlight-item').css('max-width', treatmentItemWidth - 60);
	});

	function treatmentSpotlightAnimation() {
		document.querySelectorAll('.treatment-spotlight .treatment-spotlight-item').forEach(item => {

			item.addEventListener('click', () => {
				item.classList.add('active');

				document.querySelectorAll('.treatment-spotlight .treatment-spotlight-item').forEach(item2 => {

					if(item != item2) {
						item2.classList.remove('active');
					}

				});

			});

		});
	}

	treatmentSpotlightAnimation();

	function makeFormImageSameSize() {
		let imageHeight = $('.refer-form .gform_wrapper').outerHeight();

		$('.refer-form .image').css('height', 'auto');
		$('.refer-form .image').css('min-height', imageHeight);
	}

	makeFormImageSameSize();


	function makeContentSameAsImage(imageContainer) {

		$(imageContainer).each(function(index, item) {
			let imageHeight = $(item).find('img').height();

			if ($(window).width() > 1300) {
				$(item).find('.content-wrapper').css('height', 'auto');
				$(item).find('.content-wrapper').css('min-height', imageHeight);
			} else {
				$(item).find('.content-wrapper').css('height', 'auto');
				$(item).find('.content-wrapper').css('min-height', '100%');
			}

		});

	}

	makeContentSameAsImage('.image-content-block');
	makeContentSameAsImage('.meet-the-team-block');

	$(window).on('resize', function() {
		makeContentSameAsImage();
		makeFormImageSameSize();
	});

	document.querySelectorAll('.treatments-dropdown .treatments-menu .menu-row').forEach(row => {

		row.addEventListener('click', () => {

			row.classList.add('active');

			document.querySelectorAll('.treatments-dropdown .treatments-menu .menu-row').forEach(row2 => {

				if(row != row2) {
					row2.classList.remove('active');
				}

			});

		});

	});

	$(window).on('resize', function() {
		$('.team-members-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$(window).on('resize', function() {
		$('.before-after .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$('.before-after .slick-arrow').html('<div class=arrow><span></span><span></span></div>');

	$('.team-members-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');

	$(window).on('resize', function() {
		$('.videos-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$('.videos-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');

	$(window).on('resize', function() {
		$('.related-conditions-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$('.related-conditions-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');

	$(window).on('resize', function() {
		$('.related-testimonials-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$('.related-testimonials-block .slick-arrow').html('<div class=arrow><span></span><span></span></div>');


	$(window).on('resize', function() {
		$('.related-articles .content .slick-arrow').html('<div class=arrow><span></span><span></span></div>');
	});

	$('.related-articles .content .slick-arrow').html('<div class=arrow><span></span><span></span></div>');

	if(document.querySelectorAll('.all-team-members .member').length > 12) {
		document.querySelector('.all-team-members .load-more').classList.add('active');
	} else {
		$('.all-team-members .members-wrapper').addClass('active');
	}

	$('.all-team-members .load-more').on('click', (e) => {
		e.preventDefault();

		if($('.all-team-members .load-more').hasClass('on')) {
			$('.all-team-members .members-wrapper').removeClass('active');
			$('.all-team-members .load-more').html('Load More');
			$('.all-team-members .load-more').removeClass('on');
		} else {
			$('.all-team-members .members-wrapper').addClass('active');
			$('.all-team-members .load-more').html('Show Less');
			$('.all-team-members .load-more').addClass('on');
		}
	});

	$('.content-treatment .main-content .load').on('click', (e) => {
		e.preventDefault();

		if($('.content-treatment .main-content .load').hasClass('active')) {
			$('.content-treatment .main-content .content').removeClass('active');
			$('.content-treatment .main-content .load').html('Load More');
			$('.content-treatment .main-content .load').removeClass('active');
			$('.content-treatment .main-content .overlay').css('display', 'block');
		} else {
			$('.content-treatment .main-content .content').addClass('active');
			$('.content-treatment .main-content .load').html('Show Less');
			$('.content-treatment .main-content .load').addClass('active');
			$('.content-treatment .main-content .overlay').css('display', 'none');
		}
	});

	$('.content-post .post-big-content-block .load').on('click', (e) => {
		e.preventDefault();

		if($('.content-post .post-big-content-block .load').hasClass('active')) {
			$('.content-post .post-big-content-block .content').removeClass('active');
			$('.content-post .post-big-content-block .load').html('Load More');
			$('.content-post .post-big-content-block .load').removeClass('active');
			$('.content-post .post-big-content-block .overlay').css('display', 'block');
		} else {
			$('.content-post .post-big-content-block .content').addClass('active');
			$('.content-post .post-big-content-block .load').html('Show Less');
			$('.content-post .post-big-content-block .load').addClass('active');
			$('.content-post .post-big-content-block .overlay').css('display', 'none');
		}
	});

    $('.accordion .accordion-item .question').click(function() {

        $('.accordion .accordion-item .question').not(this).removeClass('active');

        $(this).toggleClass('active');

        console.log('test');
    });

	document.querySelectorAll('.conditions-dropdown .conditions-menu .menu-row').forEach(row => {

		row.addEventListener('click', () => {

			row.classList.add('active');

			document.querySelectorAll('.conditions-dropdown .conditions-menu .menu-row').forEach(row2 => {

				if(row != row2) {
					row2.classList.remove('active');
				}

			});

		});

	});

	document.querySelectorAll('.videos-block').forEach(videoBlock => {

		videoBlock.querySelectorAll('.videos .item').forEach(item => {

			item.querySelector('.toggle a').addEventListener('click', (e) => {
				e.preventDefault();

				document.querySelector('.modal').classList.add('active');
				document.querySelector('.modal iframe').src = e.target.dataset.src;
			});

		});

	});

	document.querySelector('.modal .close').addEventListener('click', () => {
		document.querySelector('.modal').classList.remove('active');
		document.querySelector('.modal iframe').src = '';
	});

	$('.related-articles .post .image').each(function(index, item) {
		let imageWidth = $(item).width();

		$(item).css('height', imageWidth);
		$(item).css('min-height', imageWidth);

		$(window).on('resize', function() {

			let imageWidth = $(item).width();

			$(item).css('height', imageWidth);
			$(item).css('min-height', imageWidth);
		});

	});

	$('.post-archive-wrapper .post .image').each(function(index, item) {
		let imageWidth = $(item).width();

		$(item).css('height', imageWidth);
		$(item).css('min-height', imageWidth);

		$(window).on('resize', function() {

			let imageWidth = $(item).width();

			$(item).css('height', imageWidth);
			$(item).css('min-height', imageWidth);
		});

	});

	$(".widget-area aside.widget_categories select").select2();
	$(".widget-area aside.widget_archive select").select2();
	$(".gallery-menu-mobile-select").select2();
	$(".gfield select").select2();

	$(".gform_footer input.button").on('click', () => {
		$(".gfield_select").select2();
		setTimeout(() => {
			$(".gfield_select").select2();
		}, 500);
	});

	$(".select2 #select2-archives-dropdown-2-container").attr('title', 'Archives').html('Archives');
	$(".select2 #select2-cat-container").attr('title', 'Filter').html('Filter');

	$('.pagination li').each(function(index, item) {

		if($(this).children('a.next')) {
			$(this).children('a.next').parent().css('display', 'none');
		}

		if($(this).children('a.prev')) {
			$(this).children('a.prev').parent().css('display', 'none');
		}

		let text = $(this).children().text();

		$(this).children().text(("00" + text).slice(-3));

	});


	if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
		$('.gallery-menu').addClass('active');
	} else {
		$('.gallery-menu').removeClass('active');
	}

	$(document).scroll(function() {

		if($(document).scrollTop() >= ($('#wrapper-navbar').height() - 120)) {
			$('.gallery-menu').addClass('active');
		} else {
			$('.gallery-menu').removeClass('active');
		}

	});

	if(document.querySelectorAll('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
		if(document.querySelector('.gallery .load')) {
			document.querySelector('.gallery .load').classList.add('display');
		}
	} else {
		if(document.querySelector('.gallery .load')) {
			document.querySelector('.gallery .load').classList.remove('display');
		}
	}

	document.querySelectorAll('.gallery-menu a').forEach(menuItem => {

		if(menuItem.classList.contains('active')) {
			let menuType = menuItem.dataset.type;

			document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

				if(!row.classList.contains(menuType)) {
					row.classList.remove('active');
				} else {
					row.classList.add('active');
				}

			});
		}

		menuItem.addEventListener('click', (e) => {
			e.preventDefault();

			window.scroll(0, document.querySelector('.gallery-menu').offsetTop + 400);

			document.querySelectorAll('.gallery-menu a').forEach(menuItem2 => {

				if(menuItem != menuItem2) {
					menuItem2.classList.remove('active');
				} else {
					menuItem2.classList.add('active');
				}

			});

			if(menuItem.classList.contains('active')) {
				let menuType = menuItem.dataset.type;

				document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

					if(!row.classList.contains(menuType)) {
						row.classList.remove('active');
					} else {
						row.classList.add('active');
					}

				});
			}

		});

	});

	$('.gallery-menu a').on('click', function () {
		setTimeout(function () {
			if($('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
				$('.gallery .load').addClass('display');
			} else {
				$('.gallery .load').removeClass('display');
			}
		}, 300);
	});

	$('.gallery .load').on('click', (e) => {

		e.preventDefault();

		if($('.gallery .load').hasClass('active')) {
			$('.gallery .load').removeClass('active');
			$('.gallery .load').html('Load More');

			$('.gallery-menu a').each(function() {

				if($(this).hasClass('active')) {
					let buttonData = $(this).data('type');

					$('.gallery .gallery-wrapper').each(function() {
						if($(this).hasClass(buttonData)) {
							$(this).removeClass('open');
						}
					});
				}

			});

		} else {
			$('.gallery .load').addClass('active');
			$('.gallery .load').html('Show Less');

			$('.gallery-menu a').each(function() {

				if($(this).hasClass('active')) {
					let buttonData = $(this).data('type');

					$('.gallery .gallery-wrapper').each(function() {
						if($(this).hasClass(buttonData)) {
							$(this).addClass('open');
						}

						if(!$(this).hasClass(buttonData)) {
							$(this).removeClass('open');
						}
					});
				}

			});
		}

	});

	$(window).on('resize', function() {
		if ($(window).width() < 768) {
			document.querySelectorAll('.gallery-menu-mobile .select2-selection__rendered').forEach(menuItem => {

				let menuType = menuItem.innerHTML.toLowerCase();

				if(document.querySelectorAll('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
					document.querySelector('.gallery .load-mobile').classList.add('display');
				} else {
					document.querySelector('.gallery .load-mobile').classList.remove('display');
				}
				document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

					if(!row.classList.contains(menuType)) {
						row.classList.remove('active');
					} else {
						row.classList.add('active');
					}

					if(document.querySelector('.gallery .load-mobile').classList.contains('active')) {
						document.querySelectorAll('.gallery .gallery-wrapper').forEach(wrapper => {

							if(!wrapper.classList.contains(menuType)) {
								wrapper.classList.remove('open');
							}
						});
					}

				});

				setInterval(() => {
					let menuType = document.querySelector('.gallery-menu-mobile .select2-selection__rendered').innerHTML.toLowerCase();

					if(document.querySelectorAll('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
						document.querySelector('.gallery .load-mobile').classList.add('display');
					} else {
						document.querySelector('.gallery .load-mobile').classList.remove('display');
					}

					document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

						if(!row.classList.contains(menuType)) {
							row.classList.remove('active');
						} else {
							row.classList.add('active');
						}

						if(document.querySelector('.gallery .load-mobile').classList.contains('active')) {
							document.querySelectorAll('.gallery .gallery-wrapper').forEach(wrapper => {

								if(!wrapper.classList.contains(menuType)) {
									wrapper.classList.remove('open');
								}
							});
						}

					});
				}, 400);

			});
		}
	});

	if ($(window).width() < 768) {
		document.querySelectorAll('.gallery-menu-mobile .select2-selection__rendered').forEach(menuItem => {

			let menuType = menuItem.innerHTML.toLowerCase();

			if(document.querySelectorAll('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
				document.querySelector('.gallery .load-mobile').classList.add('display');
			} else {
				document.querySelector('.gallery .load-mobile').classList.remove('display');
			}
			document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

				if(!row.classList.contains(menuType)) {
					row.classList.remove('active');
				} else {
					row.classList.add('active');
				}

				if(document.querySelector('.gallery .load-mobile').classList.contains('active')) {
					document.querySelectorAll('.gallery .gallery-wrapper').forEach(wrapper => {

						if(!wrapper.classList.contains(menuType)) {
							wrapper.classList.remove('open');
						}
					});
				}

			});

			setInterval(() => {
				let menuType = document.querySelector('.gallery-menu-mobile .select2-selection__rendered').innerHTML.toLowerCase();

				if(document.querySelectorAll('.gallery .gallery-wrapper.active .gallery-row').length > 4) {
					document.querySelector('.gallery .load-mobile').classList.add('display');
				} else {
					document.querySelector('.gallery .load-mobile').classList.remove('display');
				}

				document.querySelectorAll('.gallery .gallery-wrapper').forEach(row => {

					if(!row.classList.contains(menuType)) {
						row.classList.remove('active');
					} else {
						row.classList.add('active');
					}

					if(document.querySelector('.gallery .load-mobile').classList.contains('active')) {
						document.querySelectorAll('.gallery .gallery-wrapper').forEach(wrapper => {

							if(!wrapper.classList.contains(menuType)) {
								wrapper.classList.remove('open');
							}
						});
					}

				});
			}, 400);

		});
	}

	$('.gallery .load-mobile').on('click', (e) => {

		e.preventDefault();

		if($('.gallery .load-mobile').hasClass('active')) {
			$('.gallery .load-mobile').removeClass('active');
			$('.gallery .load-mobile').html('Load More');

			let menuType = document.querySelector('.gallery-menu-mobile .select2-selection__rendered').innerHTML.toLowerCase();

			$('.gallery .gallery-wrapper').each(function() {
				if($(this).hasClass(menuType)) {
					$(this).removeClass('open');
				}
			});

		} else {
			$('.gallery .load-mobile').addClass('active');
			$('.gallery .load-mobile').html('Show Less');

			let menuType = document.querySelector('.gallery-menu-mobile .select2-selection__rendered').innerHTML.toLowerCase();

			$('.gallery .gallery-wrapper').each(function() {

				if(!$(this).hasClass(menuType)) {
					$(this).removeClass('open');
				}

				if($(this).hasClass(menuType)) {
					$(this).addClass('open');
				}
			});
		}

	});

	function addListenerForm(name) {
		$(`input[name=${name}`).keyup(function() {
			var check = $(this).val();
			if(check == '') {
				$('#gform_submit_button_1').attr("disabled", true);
			} else {
				if($('input[name="name"]').val() == '' || $('input[name="address"]').val() == '' || $('input[name="phone"]').val() == '' || $('input[name="email"]').val() == '' || $('input[name="dob"]').val() == '') {
					$('#gform_submit_button_1').attr("disabled", true);
				} else {
					$('#gform_submit_button_1').attr("disabled", false);
				}
			}
		});
	}

	addListenerForm('name');
	addListenerForm('address');
	addListenerForm('phone');
	addListenerForm('email');
	addListenerForm('dob');

	$('.select2-results').click(function() {
		$('#input_1_26').val($('.refer-hero .form-top .select2-selection__rendered').text());
	})


	if ( $('.refer-hero .form-top') ) {

		$('.refer-hero .form-top input[name="name"]').keyup(function() {
			$('#input_1_21').val($(this).val());
		});
		$('.refer-hero .form-top input[name="address"]').keyup(function() {
			$('#input_1_22').val($(this).val());
		});
		$('.refer-hero .form-top input[name="phone"]').keyup(function() {
			$('#input_1_23').val($(this).val());
		});
		$('.refer-hero .form-top input[name="email"]').keyup(function() {
			$('#input_1_24').val($(this).val());
		});
		$('.refer-hero .form-top input[name="dob"]').keyup(function() {
			$('#input_1_25').val($(this).val());
		});

		$('.refer-hero .form-top .select2').click(function() {
			$('#input_1_26').val($('.refer-hero .form-top .select2-selection__rendered').text());
		});

		setInterval(() => {
			$('#input_1_26').val($('.refer-hero .form-top .select2-selection__rendered').text());
		}, 400)

		$('.refer-hero .form-top textarea[name="additional_message"]').keyup(function() {
			$('#input_1_27').val($(this).val());
		});

		$('input[name="name"], input[name="address"], input[name="phone"], input[name="email"], input[name="dob"]').each(function() {
			var check = $(this).val();
			if(check == '') {
				$('#gform_submit_button_1').attr("disabled", true);
			} else {
				$('#gform_submit_button_1').attr("disabled", false);
			}
		});
	}

	if ($(window).width() > 991) {

		$('.ti-widget-container').removeClass('ti-col-4');
		$('.ti-widget-container').removeClass('ti-col-5');

		$('.ti-widget-container').addClass('ti-col-3');
	}

	setInterval(() => {
		if ($(window).width() > 991) {
			$('.ti-widget-container').removeClass('ti-col-4');
			$('.ti-widget-container').removeClass('ti-col-5');

			$('.ti-widget-container').addClass('ti-col-3');
		}
	}, 400);


	function heroResize(name) {
		let imageHeight = $(name).height();

		$(name).css('width', imageHeight);
		// $(name).css('min-width', imageHeight);

		$(window).on('resize', function() {

			let imageWidth = $(name).width();

			$(name).css('width', imageHeight);
			// $(name).css('min-width', imageHeight);
		});
	}

	heroResize('.ethos-hero .ethos-hero-img');
	heroResize('.experience-hero .experience-hero-img');

});
